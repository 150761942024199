.btnConocePropuesta2{
    width: 26.4rem;
    height: 3.2rem;
    background-color: var(--violet-dark-web-space);
    border: none;
    display: flex;
    border-radius: 2rem;
    align-items: center;
    cursor: pointer;
    transition: all 0.1s ease-in;
    box-shadow: #00000033 0.4rem 0.4rem 2rem;
    margin-top: 2.6rem;
    text-decoration: none;
}

.btnConocePropuesta2 img{
    margin-left: 1.3rem;
}

.btnConocePropuesta2 p{
    margin-left: 0.5rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.792rem;
    color: #FFFFFF;
    font-family: "Akzidenz-Grotesk Pro Ext", sans-serif;
}

.btnConocePropuesta2:active {
    transform: scale(1.05);
}