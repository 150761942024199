.btnConocePropuesta2 {
  background-color: var(--violet-dark-web-space);
  cursor: pointer;
  border: none;
  border-radius: 2rem;
  align-items: center;
  width: 26.4rem;
  height: 3.2rem;
  margin-top: 2.6rem;
  text-decoration: none;
  transition: all .1s ease-in;
  display: flex;
  box-shadow: .4rem .4rem 2rem #0003;
}

.btnConocePropuesta2 img {
  margin-left: 1.3rem;
}

.btnConocePropuesta2 p {
  color: #fff;
  margin-left: .5rem;
  font-family: Akzidenz-Grotesk Pro Ext, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.792rem;
}

.btnConocePropuesta2:active {
  transform: scale(1.05);
}
/*# sourceMappingURL=index.ff766608.css.map */
